export const properties = {
  /** General */

  /** footer */
  com_feria_label_footer_terms_conditions: "Términos y Condiciones",
  /** Page not found */
  com_feria_label_page_404_title: "Oops!",
  com_feria_label_page_404_subtitle: "Página no encontrada",

  /** Menu recepciónlabel */
  com_feria_label_recepcion_menu_title: "Ir a recepción",

  /** Menu dashboard label */
  com_feria_label_dashboard_menu_principal: "Inicio",
  com_feria_label_dashboard_menu_general_statistics: "Graficas",
  /** feria admin menu */
  com_feria_label_dashboard_menu_feria: "Administración de feria",
  com_feria_label_dashboard_menu_feria_params: "Parametros generales",
  com_feria_label_dashboard_menu_feria_standcategory: "Categoria stand",
  com_feria_label_dashboard_menu_feria_stand: "Stands",
  com_feria_label_dashboard_menu_feria_mainroom: "Sala principal",
  com_feria_label_dashboard_menu_feria_mainroom_talks: "Actividades",
  com_feria_label_dashboard_menu_feria_mainroom_polls: "Encuestas",
  /** reports menu */
  com_feria_label_dashboard_menu_report: "Reportes",
  com_feria_label_dashboard_menu_report_users: "Reporte de usuarios",
  com_feria_label_dashboard_menu_report_binnacleusers:
    "Reporte de bitacora usuarios",
  com_feria_label_dashboard_menu_report_surveyusers:
    "Reporte de encuestas usuarios",
  com_feria_label_dashboard_menu_report_visitasstand:
    "Reporte de visitas stand",
  com_feria_label_dashboard_menu_report_visitascharla:
    "Reporte de visitas charla",
  /** general maintenace menu */
  com_feria_label_dashboard_menu_generalmaint: "Mantenimientos general",
  com_feria_label_dashboard_menu_generalmaint_catalogs: "Catalogos",
  com_feria_label_dashboard_menu_generalmaint_gender: "Sexo",
  com_feria_label_dashboard_menu_generalmaint_provincia: "Provincia",
  com_feria_label_dashboard_menu_generalmaint_canton: "Cantón",
  com_feria_label_dashboard_menu_generalmaint_district: "Distrito",
  com_feria_label_dashboard_menu_generalmaint_region: "Región",
  com_feria_label_dashboard_menu_generalmaint_studycenter: "Tipo de estudiante",
  com_feria_label_dashboard_menu_generalmaint_visitortype: "Tipo de visitante",
  com_feria_label_dashboard_menu_generalmaint_talkstatus: "Estados de charla",
  com_feria_label_dashboard_menu_generalmaint_phonetype: "Tipo de teléfono",
  com_feria_label_dashboard_menu_generalmaint_socialNetworkType:
    "Tipo de red social",
  com_feria_label_dashboard_menu_generalmaint_multimediatype:
    "Tipo de multimedia",
  com_feria_label_dashboard_menu_generalmaint_geographicregion:
    "Region Geografica",
  com_feria_label_dashboard_menu_generalmaint_surveyoption: "Opciones de encuesta",
  /** security menu */
  com_feria_label_dashboard_menu_security: "Seguridad",
  com_feria_label_dashboard_menu_security_user: "Usuarios",
  com_feria_label_dashboard_menu_security_roles: "Roles",
  com_feria_label_dashboard_menu_security_authorities: "Autoridades",

  com_feria_label_dashboard_menu_connected_as: "Conectado como:",

  /** Loading title */
  com_feria_label_loading_title: "Cargando...",

  /** General label */
  com_feria_label_yes: "Si",
  com_feria_label_not: "No",
  com_feria_label_add_subtitle: "Ingrese los datos indicados",
  com_feria_label_update_subtitle: "Modifique los datos indicados",
  com_feria_label_code: "Código",
  com_feria_label_phone: "Teléfono",
  com_feria_label_url: "Url",
  com_feria_label_name: "Nombre",
  com_feria_label_email: "Correo",
  com_feria_label_description: "Descripción",
  com_feria_label_comment: "Comentario",
  com_feria_label_select: "Seleccione",
  com_feria_label_select_other: "Otro",
  com_feria_label_not_apply: "No aplica",
  /** General error labels in forms */
  com_feria_label_field_required: "Campo requerido",
  com_feria_label_field_format: "Formato invalido",
  com_feria_label_field_img_upload: "Formato o tamaño de imagen invalido",

  /** General sucess labels in process */
  com_feria_label_request_save: "Registro insertado con éxito",
  com_feria_label_request_incription_save: "Inscripción realizada con éxito, se le envió un correo con instrucciones al correo ingresado.",
  com_feria_label_request_incription_update:
    "Actualización de usuario realizada con éxito",
  com_feria_label_request_incription_updatepass:
    "Actualización de contraseña realizada con éxito",
  com_feria_label_request_update: "Registro actualizado con éxito",
  com_feria_label_request_delete: "Registro eliminado con éxito",
  /** General error labels in process */
  com_feria_label_request_connection: "Falla en solicitud al servidor",
  com_feria_label_request_unauthorized:
    "No tiene permiso para realizar esta acción",
  com_feria_label_request_badrequest:
    "Falla en parametros dentro de la solicitud al servidor",

  /** General button labels */
  com_feria_label_button_accept_title: "Aceptar",
  com_feria_label_button_cancel_title: "Cancelar",
  com_feria_label_button_save_title: "Registrar",
  com_feria_label_button_update_title: "Actualizar",
  com_feria_label_button_delete_title: "Eliminar",
  com_feria_label_button_back_title: "Atrás",
  com_feria_label_button_back2_title: "Volver",
  com_feria_label_button_add_title: "Agregar",
  com_feria_label_button_search_title: "Buscar",
  com_feria_label_button_clear_title: "Limpiar",
  com_feria_label_button_login_title: "Ingresar",
  com_feria_label_button_resetpassword_title: "Recuperar contraseña",
  com_feria_label_button_close_title: "Cerrar",
  com_feria_label_button_view_title: "Ver",
  com_feria_label_button_exit_title: "Salir",
  com_feria_label_button_downloadreport_title: "Descargar reporte (PDF)",
  com_feria_label_button_downloadreportexcel_title: "Descargar reporte (EXCEL)",
  com_feria_label_button_downloadreport2_title: "Ficha técnica",

  /** Table label */
  com_feria_label_table_filter_title: "Filtrar",
  com_feria_label_table_filter_placeholder: "Filtro de búsqueda",

  com_feria_label_table_action: "Acciones",
  com_feria_label_table_empty: "No existen registros",

  /** General modal label  */
  com_feria_label_modal_delete_title:
    "Esta seguro de eliminar el siguiente registro?",
  com_feria_label_modal_viewimagen_title: "Previsualización",
  com_feria_label_modal_roles_title: "Seleccione rol",
  com_feria_label_modal_termsandconditions_title: "Términos y Condiciones",
  com_feria_label_modal_typevisits_title: "Tipos de visitantes",
  /** header public */
  com_feria_label_public_header_fiex: "¿Qué es la FIEX?",
  com_feria_label_public_header_cuando: "¿Cuándo?",
  com_feria_label_public_header_exhibitors: "Charlistas",
  com_feria_label_public_header_artist: "Artistas",
  com_feria_label_public_header_contact: "Contacto",
  com_feria_label_public_header_masinformacion: "+ Información",
  com_feria_label_public_header_program: "Agenda",
  com_feria_label_public_header_inscription: "Inscripciones",
  com_feria_label_public_header_fair: "Feria",
  com_feria_label_public_header_login: "Ingresar",
  com_feria_label_public_header_submenu_profile: "Perfil",
  com_feria_label_public_header_submenu_admin: "Administración",
  com_feria_label_public_header_submenu_logout: "Salir",

  /** Sesion */
  com_feria_label_sesion_modal_title: "Validación de sesión",
  com_feria_label_sesion_question: "¿Desea continuar la sesión?",
  /** login */
  com_feria_label_login_title: "Ingresar a la feria",
  com_feria_label_login_identification_title: "Identificación",
  com_feria_label_login_pass_title: "Contraseña",
  com_feria_label_login_rol_title: "Roles",
  com_feria_label_login_inscription_1: "Realizar proceso de ",
  com_feria_label_login_inscription_2: "Inscripción",
  /** login error*/
  com_feria_label_login_error: "Identificación o clave invalida",
  com_feria_label_login_general_error:
    "Error en proceso de ingresar a la feria",
  com_feria_label_login_rol_error:
    "Error en proceso de ingresar a la feria, usuario sin rol",
  com_feria_label_login_revalidatetoken_error:
    "Error en proceso de revalidar sesión de usuario",
  com_feria_label_login_logout_error: "Error en proceso de cerrar sesion",

  
  
  /** Recuperar clave */
  com_feria_label_resetpass_title: "Recuperar contraseña",
  com_feria_label_resetpass_identification_title: "Identificación",
  com_feria_label_resetpass_rol_title: "Roles",
  com_feria_label_resetpass_inscription_1: "Si ya cuenta con acceso ",
  com_feria_label_resetpass_inscription_2: "Inicie sesión",
  /** login error Recuperar clave*/
  com_feria_label_resetpass_error: "Identificación invalida",
  com_feria_label_resetpass_general_error:
    "La identificación ingresada no existe, por favor verifique o escriba al correo fiex@uned.ac.cr",
  com_feria_label_resetpass_rol_error:
    "La identificación ingresada no existe, por favor verifique o escriba al correo fiex@uned.ac.cr",
  com_feria_label_resetpass_revalidatetoken_error:
    "Error en proceso de revalidar sesión de usuario",
  com_feria_label_resetpass_success: "Se envío un correo con los pasos de recuperación de su contraseña al correo registrado. Por favor verifique (Si no lo recibió, escriba al correo fiex@uned.ac.cr)",
  
  // Properties para la Feria de empleados
  // com_feria_label_resetpass_rol_error:
  //   "Error al intentar recuperar la contraseñe del usuario, por favor verifique o escriba al correo feriavirtualempleo@uned.ac.cr",
  // com_feria_label_resetpass_revalidatetoken_error:
  //   "Error en proceso de revalidar sesión de usuario",
  // com_feria_label_login_logout_error: "Error en proceso de cerrar sesion",


  /** Contacto */
  com_feria_label_contact_success: "Correo enviado correctamente",
  com_feria_label_contact_error: "Error al enviar correo",

  /** Graphics label */
  com_feria_label_graphic_card_title: "Graficas generales",

  /** Gender label */
  com_feria_label_gender_title: "Mantenimiento de generos",
  com_feria_label_gender_card_title: "Catalogo de generos",
  com_feria_label_gender_add_title: "Agregar genero",
  com_feria_label_gender_update_title: "Actualizar genero",
  /** Gender service error label */
  com_feria_label_gender_find_error: "Error en consulta de generos",
  com_feria_label_gender_findid_error: "Error en consulta por id de genero",
  com_feria_label_gender_save_error: "Error en proceso de guardar genero",
  com_feria_label_gender_update_error: "Error en proceso de actualizar genero",
  com_feria_label_gender_delete_error: "Error en proceso de eliminar genero",

  /** StudyCenter label */
  com_feria_label_studycenter_title: "Mantenimiento de tipos de estudiante",
  com_feria_label_studycenter_card_title: "Catalogo de tipo de estudiante",
  com_feria_label_studycenter_add_title: "Agregar centro de estudio",
  com_feria_label_studycenter_update_title: "Actualizar centro de estudio",
  /** StudyCenter service error label */
  com_feria_label_studycenter_find_error:
    "Error en consulta de tipo de estudiante",
  com_feria_label_studycenter_findid_error:
    "Error en consulta por id de  tipo de estudiante",
  com_feria_label_studycenter_save_error:
    "Error en proceso de guardar  tipo de estudiante",
  com_feria_label_studycenter_update_error:
    "Error en proceso de actualizar  tipo de estudiante",
  com_feria_label_studycenter_delete_error:
    "Error en proceso de eliminar  tipo de estudiante",

  /** visitorType label */
  com_feria_label_visitortype_title: "Mantenimiento de tipo de visitante",
  com_feria_label_visitortype_card_title: "Catalogo de tipo de visitante",
  com_feria_label_visitortype_add_title: "Agregar tipo de visitante",
  com_feria_label_visitortype_update_title: "Actualizar tipo de visitante",
  /** visitorType service error label */
  com_feria_label_visitortype_find_error:
    "Error en consulta de tipo de visitantes",
  com_feria_label_visitortype_findid_error:
    "Error en consulta por id de tipo de visitante",
  com_feria_label_visitortype_save_error:
    "Error en proceso de guardar tipo de visitante",
  com_feria_label_visitortype_update_error:
    "Error en proceso de actualizar tipo de visitante",
  com_feria_label_visitortype_delete_error:
    "Error en proceso de eliminar tipo de visitante",

  /** Provincia label */
  com_feria_label_provincia_title: "Mantenimiento de provincias",
  com_feria_label_provincia_card_title: "Catalogo de provincias",
  com_feria_label_provincia_add_title: "Agregar provincia",
  com_feria_label_provincia_update_title: "Actualizar provincia",
  /** Provincia service error label */
  com_feria_label_provincia_find_error: "Error en consulta de provincias",
  com_feria_label_provincia_findid_error:
    "Error en consulta por id de provincia",
  com_feria_label_provincia_save_error: "Error en proceso de guardar provincia",
  com_feria_label_provincia_update_error:
    "Error en proceso de actualizar provincia",
  com_feria_label_provincia_delete_error:
    "Error en proceso de eliminar provincia",

  /** Region Geografica label */
  com_feria_label_geographicregion_title:
    "Mantenimiento de regiones geográficas",
  com_feria_label_geographicregion_card_title:
    "Catalogo de regiones geográficas",
  com_feria_label_geographicregion_add_title: "Agregar región geográfica",
  com_feria_label_geographicregion_update_title: "Actualizar región geográfica",
  /** Region Geografica service error label */
  com_feria_label_geographicregion_find_error:
    "Error en consulta de regiones geográficas",
  com_feria_label_geographicregion_findid_error:
    "Error en consulta por id de región geográfica",
  com_feria_label_geographicregion_save_error:
    "Error en proceso de guardar región geográfica",
  com_feria_label_geographicregion_update_error:
    "Error en proceso de actualizar región geográfica",
  com_feria_label_geographicregion_delete_error:
    "Error en proceso de eliminar región geográfica",

  /** Cantón label */
  com_feria_label_canton_title: "Mantenimiento de cantones",
  com_feria_label_canton_card_title: "Catalogo de cantones",
  com_feria_label_canton_add_title: "Agregar Cantón",
  com_feria_label_canton_update_title: "Actualizar Cantón",
  com_feria_label_canton_modal_provincia: "Provincias",
  com_feria_label_canton_filter_select: "Seleccione provincia",
  /** Cantón service error label */
  com_feria_label_canton_find_error: "Error en consulta de cantones",
  com_feria_label_canton_findid_error: "Error en consulta por id de Cantón",
  com_feria_label_canton_save_error: "Error en proceso de guardar Cantón",
  com_feria_label_canton_update_error: "Error en proceso de actualizar Cantón",
  com_feria_label_canton_delete_error: "Error en proceso de eliminar Cantón",

  /** distrito label */
  com_feria_label_distrito_title: "Mantenimiento de distritos",
  com_feria_label_distrito_card_title: "Catalogo de distritos",
  com_feria_label_distrito_add_title: "Agregar distrito",
  com_feria_label_distrito_update_title: "Actualizar distrito",
  com_feria_label_distrito_modal_canton: "Cantones",
  com_feria_label_distrito_filter_select: "Seleccione Cantón",
  /** Cantón service error label */
  com_feria_label_distrito_find_error: "Error en consulta de distritos",
  com_feria_label_distrito_findid_error: "Error en consulta por id de distrito",
  com_feria_label_distrito_save_error: "Error en proceso de guardar distrito",
  com_feria_label_distrito_update_error:
    "Error en proceso de actualizar distrito",
  com_feria_label_distrito_delete_error:
    "Error en proceso de eliminar distrito",

  /** Rol label */
  com_feria_label_rol_title: "Mantenimiento de roles",
  com_feria_label_rol_card_title: "Catalogo de roles",
  com_feria_label_rol_add_title: "Agregar rol",
  com_feria_label_rol_applySurvey_title: "Aplica encuesta",
  com_feria_label_rol_update_title: "Actualizar rol",
  com_feria_label_rol_button_authorityrol_title: "Autoridades del rol",
  /** Cantón service error label */
  com_feria_label_rol_find_error: "Error en consulta de roles",
  com_feria_label_rol_findid_error: "Error en consulta por id de rol",
  com_feria_label_rol_authorities_error:
    "Error en consultar autoridades por rol",
  com_feria_label_rol_save_error: "Error en proceso de guardar rol",
  com_feria_label_rol_update_error: "Error en proceso de actualizar rol",
  com_feria_label_rol_delete_error: "Error en proceso de eliminar rol",

  /** TalkStatus label */
  com_feria_label_talkstatus_title: "Mantenimiento de estados de charla",
  com_feria_label_talkstatus_card_title: "Catalogo de estados de charla",
  com_feria_label_talkstatus_add_title: "Agregar estado de charla",
  com_feria_label_talkstatus_update_title: "Actualizar estado de charla",
  /** TalkStatus service error label */
  com_feria_label_talkstatus_find_error:
    "Error en consulta de estados de charla",
  com_feria_label_talkstatus_findid_error:
    "Error en consulta por id de estado de charla",
  com_feria_label_talkstatus_save_error:
    "Error en proceso de guardar estado de charla",
  com_feria_label_talkstatus_update_error:
    "Error en proceso de actualizar estado de charla",
  com_feria_label_talkstatus_delete_error:
    "Error en proceso de eliminar estado de charla",

  /** SystemParam label */
  com_feria_label_systemparam_card_title: "Sistema",
  com_feria_label_systemparam_card_subtitle:
    "Parámetros de configuración para el funcionamiento del sistema",
  com_feria_label_systemparam_activefair: "Activar Feria",
  com_feria_label_systemparam_activeregistration: "Activar Inscripción",
  com_feria_label_systemparam_activebirthDate: "Activar Fecha Nacimiento",
  com_feria_label_systemparam_request_update:
    "Parámetros actualizados con éxito",
  /** SystemParam service error label */
  com_feria_label_systemparam_find_error:
    "Error en consulta de parametros del sistema",
  com_feria_label_systemparam_findid_error:
    "Error en consulta por id de parametro del sistema",
  com_feria_label_systemparam_save_error:
    "Error en proceso de guardar parametro del sistema",
  com_feria_label_systemparam_update_error:
    "Error en proceso de actualizar parametro del sistema",
  com_feria_label_systemparam_delete_error:
    "Error en proceso de eliminar parametro del sistema",

  /** Authority label */
  com_feria_label_authority_title: "Mantenimiento de autoridades",
  com_feria_label_authority_card_title: "Catalogo de autoridades",
  com_feria_label_authority_add_title: "Agregar autoridad",
  com_feria_label_authority_update_title: "Actualizar autoridad",
  com_feria_label_authority_url: "Url",
  /** Authority service error label */
  com_feria_label_authority_find_error: "Error en consulta de autoridades",
  com_feria_label_authority_findid_error:
    "Error en consulta por id de autoridad",
  com_feria_label_authority_save_error: "Error en proceso de guardar autoridad",
  com_feria_label_authority_update_error:
    "Error en proceso de actualizar autoridad",
  com_feria_label_authority_delete_error:
    "Error en proceso de eliminar autoridad",

  /** User label */
  com_feria_label_user_title: "Mantenimiento de usuarios",
  com_feria_label_user_card_title: "Catalogo de usuarios",
  com_feria_label_user_add_title: "Agregar usuario",
  com_feria_label_user_update_title: "Actualizar usuario",
  com_feria_label_user_update_pass_title: "Actualizar contraseña de usuario",
  com_feria_label_user_identificationtype: "Tipo de identificación",
  com_feria_label_user_identificationtype_national: "Nacional",
  com_feria_label_user_identificationtype_foreign: "Extranjero",
  com_feria_label_user_identification: "Identificación",
  com_feria_label_user_fullname: "Nombre completo",
  com_feria_label_user_email: "Correo",
  com_feria_label_user_visitortype: "Tipo Visitante",
  com_feria_label_user_pass: "Contraseña (mínimo 8 caracteres)",
  com_feria_label_user_passrepeat: "Repetir contraseña",
  com_feria_label_user_age: "Edad",
  com_feria_label_user_birthdate: "Fecha de nacimiento",
  com_feria_label_user_phone: "Teléfono",
  com_feria_label_user_anotherstudycenter: "Otro centro de estudio",
  com_feria_label_user_anotherstudycenter2: "Centro de estudio",
  com_feria_label_user_particular: "Oficio o profesión",
  com_feria_label_user_region: "Región",
  com_feria_label_user_address: "Dirección",
  com_feria_label_user_agreementuse: "Acuerdo de uso",
  com_feria_label_user_button_updatepass_title: "Actualizar contraseña",
  com_feria_label_user_button_userrol_title: "Roles de usuario",
  /** msg t y c */
  com_feria_label_user_tc1: "Al dar click en ",
  com_feria_label_user_tc2: ", usted esta de acuerdo con los ",
  com_feria_label_user_tc3: " en este sitio web, incluso el uso de cookie.",
  /** user error msg */
  com_feria_label_user_different_passwords: "No coinciden las contraseñas",
  com_feria_label_user_pass_minlenght:
    "La contraseña no cumple con la longitud minima '8'",
  /** User service error label */
  com_feria_label_user_find_error: "Error en consulta de usuarios",
  com_feria_label_user_findid_error: "Error en consulta por id de usuario",
  com_feria_label_user_findidentification_error:
    "Error en consulta por identificación de usuario",
  com_feria_label_user_save_error: "Error en proceso de guardar usuario",
  com_feria_label_user_update_error: "Error en proceso de actualizar usuario",
  com_feria_label_user_update_pass_error:
    "Error en proceso de actualizar contraseña de usuario",
  com_feria_label_user_delete_error: "Error en proceso de eliminar usuario",

  /** UserRol label */
  com_feria_label_userrol_title: "Mantenimiento de roles por usuario",
  com_feria_label_userrol_add_title: "Ingrese un rol",
  /** UserRol service error label */
  com_feria_label_userrol_find_error:
    "Error en consulta de los roles de usuario",
  com_feria_label_userrol_findid_error:
    "Error en consulta por id del rol de usuario",
  com_feria_label_userrol_save_error:
    "Error en proceso de guardar el rol de usuario",
  com_feria_label_userrol_update_error:
    "Error en proceso de actualizar el rol de usuario",
  com_feria_label_userrol_update2_error:
    "Error en proceso de actualizar el rol del usuario a registrado",
  com_feria_label_userrol_delete_error:
    "Error en proceso de eliminar el rol de usuario",

  /** RolAuthority label */
  com_feria_label_rolauthority_title: "Mantenimiento de autoridades por rol",
  com_feria_label_rolauthority_add_title: "Ingrese una autoridad",
  /** RolAuthority service error label */
  com_feria_label_rolauthority_find_error:
    "Error en consulta de autoridades por rol",
  com_feria_label_rolauthority_findid_error:
    "Error en consulta por id de autoridad por rol",
  com_feria_label_rolauthority_save_error:
    "Error en proceso de guardar autoridad por rol",
  com_feria_label_rolauthority_update_error:
    "Error en proceso de actualizar autoridad por rol",
  com_feria_label_rolauthority_delete_error:
    "Error en proceso de eliminar autoridad por rol",

  /** PhoneType label */
  com_feria_label_phonetype_title: "Mantenimiento de tipo de teléfono",
  com_feria_label_phonetype_card_title: "Catalogo de tipo de teléfono",
  com_feria_label_phonetype_add_title: "Agregar tipo de teléfono",
  com_feria_label_phonetype_update_title: "Actualizar tipo de teléfono",
  com_feria_label_phonetype_apiurl: "Url para api (Solo si aplica)",
  /** PhoneType service error label */
  com_feria_label_phonetype_find_error:
    "Error en consulta de tipo de teléfonos",
  com_feria_label_phonetype_findid_error:
    "Error en consulta por id de tipo de teléfono",
  com_feria_label_phonetype_save_error:
    "Error en proceso de guardar tipo de teléfono",
  com_feria_label_phonetype_update_error:
    "Error en proceso de actualizar tipo de teléfono",
  com_feria_label_phonetype_delete_error:
    "Error en proceso de eliminar tipo de teléfono",

  /** SocialNetworkType label */
  com_feria_label_socialnetworktype_title:
    "Mantenimiento de tipo de red social",
  com_feria_label_socialnetworktype_card_title:
    "Catalogo de tipo de red social",
  com_feria_label_socialnetworktype_add_title: "Agregar tipo de red social",
  com_feria_label_socialnetworktype_update_title:
    "Actualizar tipo de red social",
  /** SocialNetworkType service error label */
  com_feria_label_socialnetworktype_find_error:
    "Error en consulta de tipo de red social",
  com_feria_label_socialnetworktype_findid_error:
    "Error en consulta por id de tipo de red social",
  com_feria_label_socialnetworktype_save_error:
    "Error en proceso de guardar tipo de red social",
  com_feria_label_socialnetworktype_update_error:
    "Error en proceso de actualizar tipo de red social",
  com_feria_label_socialnetworktype_delete_error:
    "Error en proceso de eliminar tipo de red social",

  /** MultimediaType label */
  com_feria_label_multimediatype_title: "Mantenimiento de tipo de multimedia",
  com_feria_label_multimediatype_card_title: "Catalogo de tipo de multimedia",
  com_feria_label_multimediatype_add_title: "Agregar tipo de multimedia",
  com_feria_label_multimediatype_update_title: "Actualizar tipo de multimedia",
  /** MultimediaType service error label */
  com_feria_label_multimediatype_find_error:
    "Error en consulta de tipo de multimedias",
  com_feria_label_multimediatype_findid_error:
    "Error en consulta por id de tipo de multimedia",
  com_feria_label_multimediatype_save_error:
    "Error en proceso de guardar tipo de multimedia",
  com_feria_label_multimediatype_update_error:
    "Error en proceso de actualizar tipo de multimedia",
  com_feria_label_multimediatype_delete_error:
    "Error en proceso de eliminar tipo de multimedia",

  /** StandCategory label */
  com_feria_label_standcategory_card_title: "Mantenimiento de categoria stand",
  com_feria_label_standcategory_add_title: "Agregar categoria stand",
  com_feria_label_standcategory_update_title: "Actualizar categoria stand",
  com_feria_label_standcategory_color: "Color Hex de la categoria",
  com_feria_label_standcategory_imagenstand: "Imagen de stand",
  com_feria_label_standcategory_viewimagenstand: "Ver imagen de stand",
  /** StandCategory service error label */
  com_feria_label_standcategory_find_error:
    "Error en consulta de categorias de stand",
  com_feria_label_standcategory_findid_error:
    "Error en consulta por id de categoria de stand",
  com_feria_label_standcategory_save_error:
    "Error en proceso de guardar categoria de stand",
  com_feria_label_standcategory_update_error:
    "Error en proceso de actualizar categoria de stand",
  com_feria_label_standcategory_delete_error:
    "Error en proceso de eliminar categoria de stand",

  /** Stand label */
  com_feria_label_stand_card_title: "Mantenimiento de stand",
  com_feria_label_stand_add_title: "Agregar stand",
  com_feria_label_stand_update_title: "Actualizar stand",
  com_feria_label_stand_schedule: "Horarios",
  com_feria_label_stand_category: "Todas las categorias",
  com_feria_label_stand_category_undefined: "Desconocida",
  com_feria_label_stand_creationdate: "Fecha creación",
  com_feria_label_stand_logo: "Logo",
  com_feria_label_stand_viewlogo: "Ver logo",
  com_feria_label_stand_url: "Url sitio web",
  com_feria_label_stand_state: "Activo",
  com_feria_label_stand_phones: "Teléfonos del stand",
  com_feria_label_stand_socialnetwork: "Redes sociales del stand",
  com_feria_label_stand_multimedia: "Multimedia del stand",
  com_feria_label_stand_members: "Integrantes del stand",
  /** Stand service error label */
  com_feria_label_stand_find_error: "Error en consulta de stands",
  com_feria_label_stand_findid_error: "Error en consulta por id de stand",
  com_feria_label_stand_save_error: "Error en proceso de guardar stand",
  com_feria_label_stand_update_error: "Error en proceso de actualizar stand",
  com_feria_label_stand_delete_error: "Error en proceso de eliminar stand",

  /** StandPhone label */
  com_feria_label_standphone_card_title: "Mantenimiento de teléfonos del stand",
  com_feria_label_standphone_add_title: "Agregar teléfono del stand",
  com_feria_label_standphone_update_title: "Actualizar teléfono del stand",
  com_feria_label_standphone_phone: "Numero de teléfono",
  com_feria_label_standphone_phonetype: "Tipo de teléfono",
  com_feria_label_standphone_phonetype_undefined: "Desconocido",
  /** StandPhone service error label */
  com_feria_label_standphone_find_error:
    "Error en consulta de teléfonos del stand",
  com_feria_label_standphone_findid_error:
    "Error en consulta por id el teléfono del stand",
  com_feria_label_standphone_save_error:
    "Error en proceso de guardar el teléfono del stand",
  com_feria_label_standphone_update_error:
    "Error en proceso de actualizar el teléfono del stand",
  com_feria_label_standphone_delete_error:
    "Error en proceso de eliminar el teléfono del stand",

  /** StandSocialNetwork label */
  com_feria_label_standsocialnetwork_card_title:
    "Mantenimiento de redes sociales del stand",
  com_feria_label_standsocialnetwork_add_title: "Agregar red social del stand",
  com_feria_label_standsocialnetwork_update_title:
    "Actualizar red social del stand",
  com_feria_label_standsocialnetwork_uri: "Url red social",
  com_feria_label_standsocialnetwork_socialnetworktype: "Tipo de red social",
  com_feria_label_standsocialnetwork_socialnetworktype_undefined: "Desconocido",
  /** StandSocialNetwork service error label */
  com_feria_label_standsocialnetwork_find_error:
    "Error en consulta de redes sociales del stand",
  com_feria_label_standsocialnetwork_findid_error:
    "Error en consulta por id de red social del stand",
  com_feria_label_standsocialnetwork_save_error:
    "Error en proceso de guardar la red social del stand",
  com_feria_label_standsocialnetwork_update_error:
    "Error en proceso de actualizar la red social del stand",
  com_feria_label_standsocialnetwork_delete_error:
    "Error en proceso de eliminar la red social del stand",

  /** StandMultimedia label */
  com_feria_label_standmultimedia_card_title:
    "Mantenimiento de multimedias del stand",
  com_feria_label_standmultimedia_add_title: "Agregar multimedia del stand",
  com_feria_label_standmultimedia_update_title:
    "Actualizar multimedia del stand",
  com_feria_label_standmultimedia_videouri: "Url del video",
  com_feria_label_standmultimedia_imagen: "Imagen del stand",
  com_feria_label_standmultimedia_imagenview: "Ver imagen",
  com_feria_label_standmultimedia_standmultimediatype: "Tipo multimedia",
  com_feria_label_standmultimedia_standmultimediatype_undefined: "Desconocido",
  /** StandMultimedia service error label */
  com_feria_label_standmultimedia_find_error:
    "Error en consulta de multimedias del stand",
  com_feria_label_standmultimedia_findid_error:
    "Error en consulta por id de multimedia del stand",
  com_feria_label_standmultimedia_save_error:
    "Error en proceso de guardar multimedia del stand",
  com_feria_label_standmultimedia_update_error:
    "Error en proceso de actualizar multimedia del stand",
  com_feria_label_standmultimedia_delete_error:
    "Error en proceso de eliminar multimedia del stand",

  /** StandMember label */
  com_feria_label_standmember_card_title:
    "Mantenimiento de integrantes del stand",
  com_feria_label_standmember_add_title: "Agregar integrante del stand",
  com_feria_label_standmember_update_title: "Actualizar integrante del stand",
  com_feria_label_standmember_fullname: "Nombre completo",
  /** StandMember service error label */
  com_feria_label_standmember_find_error:
    "Error en consulta de integrantes del stand",
  com_feria_label_standmember_findid_error:
    "Error en consulta por id de integrante del stand",
  com_feria_label_standmember_save_error:
    "Error en proceso de guardar integrante del stand",
  com_feria_label_standmember_update_error:
    "Error en proceso de actualizar integrante del stand",
  com_feria_label_standmember_delete_error:
    "Error en proceso de eliminar integrante del stand",

  /** StandVisit label */
  /** StandVisit service error label */
  com_feria_label_standvisit_find_error:
    "Error en consulta de visitas del stand",
  com_feria_label_standvisit_findid_error:
    "Error en consulta por id de visita del stand",
  com_feria_label_standvisit_save_error:
    "Error en proceso de guardar visita del stand",
  com_feria_label_standvisit_update_error:
    "Error en proceso de actualizar visita del stand",
  com_feria_label_standvisit_delete_error:
    "Error en proceso de eliminar visita del stand",

  /** Perfil */
  com_feria_label_perfil_option_title: "Opciones de perfil",
  com_feria_label_perfil_option1_title: "Datos usuario",
  com_feria_label_perfil_option2_title: "Actualizar contraseña",
  com_feria_label_perfil_option_userdata_title: "Datos del usuario",
  com_feria_label_perfil_option_passchange_title:
    "Actualizar contraseña del usuario",

  /** SurveyOption label */
  com_feria_label_surveyoption_card_title:
    "Mantenimiento de opciones de encuesta",
  com_feria_label_surveyoption_add_title: "Agregar opción de encuesta",
  com_feria_label_surveyoption_update_title: "Actualizar opción de encuesta",
  /** SurveyOption service error label */
  com_feria_label_surveyoption_find_error:
    "Error en consulta de opciones de encuesta",
  com_feria_label_surveyoption_findid_error:
    "Error en consulta por id de opción de encuesta",
  com_feria_label_surveyoption_save_error:
    "Error en proceso de guardar opción de encuesta",
  com_feria_label_surveyoption_update_error:
    "Error en proceso de actualizar opción de encuesta",
  com_feria_label_surveyoption_delete_error:
    "Error en proceso de eliminar opción de encuesta",

  /** Survey label */
  com_feria_label_survey_card_title: "Encuestas en el sistema",
  com_feria_label_survey_view_title: "Ver encuesta",
  com_feria_label_survey_add_title: "Completar encuesta",
  com_feria_label_survey_update_title: "Actualizar encuesta",
  com_feria_label_survey_codeuser: "Cod. Usuario",
  com_feria_label_survey_score: "Puntuación",
  com_feria_label_survey_surveyoption: "Deseamos servirle mejor: Seleccione el aspecto en el que debemos mejorar.",
  com_feria_label_survey_surveyoption_undefined: "Desconocida",
  com_feria_label_survey_select_score: "Seleccione una puntuación",
  com_feria_label_survey_select_surveyoption: "Seleccione una opción de la encuesta",
  /** Survey service success label */
  com_feria_label_survey_save_success: "Encuesta completada con éxito.",

  /** Survey service error label */
  com_feria_label_survey_find_error: "Error en consulta de encuestas",
  com_feria_label_survey_find2_error:
    "Error en consulta de encuestas del usuario",
  com_feria_label_survey_findid_error: "Error en consulta por id de encuesta",
  com_feria_label_survey_save_error: "Error en proceso de guardar encuesta",
  com_feria_label_survey_update_error:
    "Error en proceso de actualizar encuesta",
  com_feria_label_survey_delete_error: "Error en proceso de eliminar encuesta",

  /** FAIR label */
  com_feria_label_fair_standsection_title: "Stands",
  com_feria_label_fair_mainroom_title: "CAMPUS FERIAL",
  com_feria_label_fair_modalstand_filename: "fichatecnica.pdf",
  com_feria_label_fair_modalstand_tab1_title: "Información General",/*Información General*/
  com_feria_label_fair_modalstand_tab2_title: "Integrantes",/*Integrantes*/
  com_feria_label_fair_modalstand_tab3_title: "Imágenes",
  com_feria_label_fair_modalstand_tab4_title: "Videos",
  com_feria_label_fair_modalstand_tab5_title: "Contacto",/*Contacto*/
  com_feria_label_fair_modalstand_tab5_schedule: "Horario de atención",/*Horario de atención */
  com_feria_label_fair_modalstand_tab5_email: "Correo:",/*Correo */
  com_feria_label_fair_modalstand_tab5_website: "Sitio Web :",/*Sitio Web */
  com_feria_label_fair_modalstand_tab5_numbercontact: "Números a contactar",
  com_feria_label_fair_modalstand_tab5_socialnetwork: "Redes sociales",
  com_feria_label_fair_talks_title: "Actividades",
  com_feria_label_fair_survey_title: "Encuestas",

  /** Reporte de usuarios */
  com_feria_label_userreport_fullname_title: "Nombre completo",
  com_feria_label_userreport_roles_title: "Roles",
  com_feria_label_userreport_filename: "reporteusuarios.pdf",
  com_feria_label_userreport_filename2: "reporteusuarios",
  com_feria_label_userreport_title: "Reporte de usuarios",
  /** user report error label */
  com_feria_label_userreport_find_error: "Error en consulta de usuarios",

  /** Reporte de bitacora de usuarios */
  com_feria_label_userbinnaclereport_datefrom_title: "Fecha ingreso desde",
  com_feria_label_userbinnaclereport_dateto_title: "Fecha ingreso hasta",
  com_feria_label_userbinnaclereport_admissiondate_title: "Fecha ingreso",
  com_feria_label_userbinnaclereport_departuredate_title: "Fecha salida",
  com_feria_label_userbinnaclereport_minutesystem_title: "Minutos",
  com_feria_label_userbinnaclereport_filename: "reportebitacorausuarios.pdf",
  com_feria_label_userbinnaclereport_filename2: "reportebitacorausuarios",
  com_feria_label_userbinnaclereport_title: "Reporte de bitacoras de usuario",
  /** user binnacle report error label */
  com_feria_label_userbinnaclereport_find_error:
    "Error en consulta de bitacora de usuarios",

  /** Reporte de encuestas de usuarios */
  com_feria_label_usersurveyreport_datefrom_title: "Fecha creación desde",
  com_feria_label_usersurveyreport_dateto_title: "Fecha creación hasta",
  com_feria_label_usersurveyreport_createdate_title: "Fecha",
  com_feria_label_usersurveyreport_score_title: "Puntuación",
  com_feria_label_usersurveyreport_surveyoption_title: "Opción Encuesta",
  com_feria_label_usersurveyreport_filename: "reporteencuestausuarios.pdf",
  com_feria_label_usersurveyreport_filename2: "reporteencuestausuarios",
  com_feria_label_usersurveyreport_title: "Reporte de encuestas de usuario",
  /** user survey report error label */
  com_feria_label_usersurveyreport_find_error:
    "Error en consulta de encuestas de usuarios",

  /** Reporte de visitas por stand */
  com_feria_label_standvisitsreport_datefrom_title: "Fecha visita desde",
  com_feria_label_standvisitsreport_dateto_title: "Fecha visita hasta",
  com_feria_label_standvisitsreport_keyword_title: "Palabra clave",
  com_feria_label_standvisitsreport_standname_title: "Nombre stand",
  com_feria_label_standvisitsreport_visitorname_title: "Nombre visitante",
  com_feria_label_standvisitsreport_identification_title: "Cédula",
  com_feria_label_standvisitsreport_visitortype_title: "Tipo Visitante",
  com_feria_label_standvisitsreport_age_title: "Edad",
  com_feria_label_standvisitsreport_phone_title: "Teléfono",
  com_feria_label_standvisitsreport_email_title: "Correo",
  com_feria_label_standvisitsreport_province_title: "Provincia",
  com_feria_label_standvisitsreport_canton_title: "Cantón",
  com_feria_label_standvisitsreport_distrito_title: "Distrito",
  com_feria_label_standvisitsreport_region_title: "Región",
  com_feria_label_standvisitsreport_gender_title: "Género",
  com_feria_label_standvisitsreport_rol_title: "Rol",
  com_feria_label_standvisitsreport_contador_title: "Visitas",
  com_feria_label_standvisitsreport_date_title: "Ultima visita",
  com_feria_label_standvisitsreport_filename: "reportevisitasstand.pdf",
  com_feria_label_standvisitsreport_filename2: "reportevisitasstand",
  com_feria_label_standvisitsreport_title: "Reporte de visitas por stand",
  /** stand visits error label */
  com_feria_label_standvisitsreport_find_error:
    "Error en consulta de visitas por stand",

  /** Reporte de visitas por charla */
  com_feria_label_talkvisitsreport_talkname_title: "Nombre charla",
  com_feria_label_talkvisitsreport_filename: "reportevisitascharla.pdf",
  com_feria_label_talkvisitsreport_filename2: "reportevisitascharla",
  com_feria_label_talkvisitsreport_title: "Reporte de visitas por charla",
  /** talk visits error label */
  com_feria_label_talkvisitsreport_find_error:
    "Error en consulta de visitas por charla",

  /** Reporte de ficha tecnica */
  com_feria_label_fichatecnica_footer_title:
    "UNED - FIEX 2023",

  /** Talks labels */
  /**com_feria_label_talk_card_title: "Charlas",
  com_feria_label_talk_view_title: "Ver charlas",*/
  com_feria_label_talk_card_title: "Actividades",
  com_feria_label_talk_view_title: "Ver Actividades",
  com_feria_label_talk_add_title: "Agregar actividad",
  com_feria_label_talk_update_title: "Actualizar actividad",
  com_feria_label_talk_name_title: "Nombre",
  com_feria_label_talk_description_title: "Descripción",
  com_feria_label_talk_member_title: "Expositores",
  com_feria_label_talk_view2_title: "Ver actividad",
  com_feria_label_talk_viewvideo_title: "Ver video",
  com_feria_label_talk_date_title: "Fecha",
  com_feria_label_talk_url_title: "Url",
  com_feria_label_talk_state_title: "Estado",
  com_feria_label_talk_detail_title: "Detalle",
  com_feria_label_talk_imagentalk: "Imagen de charla",
  com_feria_label_talk_viewimagentalk: "Ver imagen de la actividad",
  /** talk service success label */
  com_feria_label_talk_save_success: "Encuesta completada con éxito.",

  /** talk service error label */
  com_feria_label_talk_find_error: "Error en consulta de actividades",
  com_feria_label_talk_find2_error: "Error en consulta de encuestas de charla",
  com_feria_label_talk_findid_error: "Error en consulta por id de charla",
  com_feria_label_talk_save_error: "Error en proceso de guardar charla",
  com_feria_label_talk_update_error: "Error en proceso de actualizar charla",
  com_feria_label_talk_delete_error: "Error en proceso de eliminar charla",
  com_feria_label_talk_undefined: "Desconocido",

  /** TalkMember label */
  com_feria_label_talkmember_card_title:
    "Mantenimiento de expositor de charla",
  com_feria_label_talkmember_add_title: "Agregar expositor de charla",
  com_feria_label_talkmember_update_title: "Actualizar expositor",
  com_feria_label_talkmember_fullname: "Nombre completo",
  com_feria_label_talkmember_academicdegree: "Grado acádemico",
  com_feria_label_talkmember_profession: "Título / Puesto",
  com_feria_label_talkmember_exhibitors: "Agregar expositor de charla",
  /** talkMember service error label */
  com_feria_label_talkmember_find_error:
    "Error en consulta de expositores de charla",
  com_feria_label_talkmember_findid_error:
    "Error en consulta por id de expositor de charla",
  com_feria_label_talkmember_save_error:
    "Error en proceso de guardar expositor de charla",
  com_feria_label_talkmember_update_error:
    "Error en proceso de actualizar expositor de charla",
  com_feria_label_talkmember_delete_error:
    "Error en proceso de eliminar expositor de charla",

  /** TalkVisit label */
  /** TalkVisit service error label */
  com_feria_label_talkvisit_find_error:
  "Error en consulta de visitas del charla",
  com_feria_label_talkvisit_findid_error:
  "Error en consulta por id de visita del charla",
  com_feria_label_talkvisit_save_error:
  "Error en proceso de guardar visita del charla",
  com_feria_label_talkvisit_update_error:
  "Error en proceso de actualizar visita del charla",
  com_feria_label_talkvisit_delete_error:
  "Error en proceso de eliminar visita del charla",
};
