import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as securityActions from "../../../actions/SecurityActions";
import * as utilActions from "../../../actions/UtilActions";
import { bindActionCreators } from "redux";
import { useForm } from "react-hook-form";
import Loading from "../../../components/utils/Loading";
import { properties } from "../../../utils/Properties_es";
import banner_login_feriaempleado from "../../../resources/images/feria_empleado/banner_login_movil.jpg";
import banner_login_feriaempleado_movil from "../../../resources/images/feria_empleado/banner_login.jpg";
import banner_login_fiex from "../../../resources/images/fiex/banner_login.jpg";
import banner_login_fiex_movil from "../../../resources/images/fiex/banner_login_movil.jpg";
import MessageManager from "../../../components/utils/MessageManager";
import { constant } from "../../../utils/Constant";
import { validateFieldStyle } from "../../../utils/UtilsFunction";
import { useEffect } from "react";
/***************************************
 * REVISION 1.0
 * Fecha: 7/6/2023
 *
 * @description Pantalla de recuperación de contraseña
 * @author vrow
 * @version 1.0
 *
 ***************************************/
const Reset = (props) => {
  const [styleForm, setStyleForm] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm();

  const banner_login =
    process.env.REACT_APP_FERIA_EMPLEADO === constant.DEFAULT_NOT
      ? banner_login_fiex
      : banner_login_feriaempleado;

  const banner_login_movil =
    process.env.REACT_APP_FERIA_EMPLEADO === constant.DEFAULT_NOT
      ? banner_login_fiex_movil
      : banner_login_feriaempleado_movil;

  useEffect(() => {
    if (props.userInfo && !props.isAuthenticate) {
      setDisabled(true);
    }
  }, [props.userInfo, props.isAuthenticate]);

  //efecto inicial
  useEffect(() => {
    // props.securityActions.logoutAction();
    setDisabled(false);
    setStyleForm("");
    reset();
  }, [props.securityActions, reset, props.successMsg, props.utilActions]);

  const onSubmit = async (data) => {
    await props.securityActions.resetAction(data);
  };

  // const handleClear = async () => {
  //   if (disabled) {
  //     await props.securityActions.logoutAction(null, {
  //       bitacoraId: props.userInfo.get("bitacoraId"),
  //       deleteBitacora: 1,
  //     });
  //     setDisabled(false);
  //   }

  //   setStyleForm("");
  //   reset();
  // };

  const handleStyleForm = () => {
    if (!styleForm) {
      setStyleForm(true);
    }
  };

  return (
    <>
      {props.isAuthenticate && <Redirect to="/" />}
      {props.isLoading && (
        <Loading title={properties.com_feria_label_loading_title} />
      )}
      {props.errorMsg && (
        <MessageManager
          type={constant.DANGER_MSG}
          msg={props.errorMsg}
          details=""
          onConfirm={props.utilActions.resetAllMsg}
        />
      )}
      {props.successMsg && (
        <MessageManager
          type={constant.SUCCESS_MSG}
          msg={props.successMsg}
          details=""
          onConfirm={props.utilActions.resetAllMsg}
        />
      )}
      <section className="public-block pb-5 bg-login">
        <div className="row mr-3 ml-3 mr-lg-0 ml-lg-0">
          <div className="container custom-container pl-0 pr-0">
            <div className="col-12">
              <div className="row">
                <div className="col-md-5 pt-4">
                  <h2 className="text-center">
                    {properties.com_feria_label_resetpass_title}
                  </h2>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row justify-content-center">
                      <div className="form-group col-10 mb-0">
                        <label htmlFor="identification">
                          {
                            properties.com_feria_label_resetpass_identification_title
                          }
                        </label>
                        <div className="input-group mb-3">
                          <div className="input-group-prepend">
                            <select
                              name="identificationType"
                              className="custom-select"
                              ref={register({ required: true })}
                              disabled={disabled}
                            >
                              <option
                                value={constant.IDENTIFICATION_NATIONAL_VALUE}
                              >
                                {
                                  properties.com_feria_label_user_identificationtype_national
                                }
                              </option>
                              <option
                                value={constant.IDENTIFICATION_FOREIGN_VALUE}
                              >
                                {
                                  properties.com_feria_label_user_identificationtype_foreign
                                }
                              </option>
                            </select>
                          </div>
                          <input
                            type="text"
                            className={`form-control ${validateFieldStyle(
                              styleForm,
                              errors.identification
                            )}`}
                            name="identification"
                            id="identification"
                            ref={register({ required: true })}
                            readOnly={disabled}
                          />
                          {errors.identification && (
                            <span className="invalid-feedback">
                              {properties.com_feria_label_field_required}
                            </span>
                          )}
                        </div>
                      </div>
                      {props.userInfo &&
                        props.userInfo.get("roles") &&
                        props.userInfo.get("roles").size > 1 &&
                        !props.isAuthenticate &&
                        disabled && (
                          <div className="form-group col-10">
                            <label htmlFor="rol">
                              {properties.com_feria_label_login_rol_title}
                            </label>
                            <select
                              name="rol"
                              id="rol"
                              className="form-control"
                              ref={register}
                            >
                              {props.userInfo.get("roles").map((r) => (
                                <option key={r.get("Id")} value={r.get("Id")}>
                                  {r.get("Codigo")}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                    </div>
                    <div className="form-group text-center">
                      {/*
                    <button
                      className="btn-login mr-2 pl-4 pr-4"
                      onClick={handleStyleForm}
                    >
                      {properties.com_feria_label_button_login_title}
                    </button>
                    Oculto el botón de limpiar
                    <button
                      type="button"
                      className="btn-login pl-4 pr-4"
                      onClick={handleClear}
                    >
                      {properties.com_feria_label_button_clear_title}
                    </button>
                    
                    Boton para recuperar contraseña */}
                      <button
                        className="btn-login mr-2 pl-4 pr-4"
                        onClick={handleStyleForm}
                      >
                        {properties.com_feria_label_button_resetpassword_title}
                      </button>
                    </div>
                    {/*{props.systemParam &&
                    props.systemParam.get("ActivarInscripcion") === "S" && (
                      <div className="form-group text-center pt-2">
                        <label>
                          {properties.com_feria_label_resetpass_inscription_1}
                        </label>{" "}
                        <Link to="/ingresar">
                          <strong>
                            {properties.com_feria_label_resetpass_inscription_2}
                          </strong>
                        </Link>
                      </div>
                    )}*/}
                  </form>
                </div>
                <div className="col-md-7 pr-0 pl-0 bgrc_banner">
                  <div className="w-100 d-none d-sm-none d-md-block d-lg-block">
                    <img
                      className="w-100"
                      src={banner_login}
                      alt="banner_login"
                    />
                  </div>
                  <div className="w-100 d-block d-sm-block d-md-none d-lg-none">
                    <img
                      className="w-100"
                      src={banner_login_movil}
                      alt="banner_login_movil"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    securityActions: bindActionCreators(securityActions, dispatch),
    utilActions: bindActionCreators(utilActions, dispatch),
  };
};

const mapStateToProps = (state, props) => {
  return {
    systemParam: state.get("pageManager").get("systemParam"),
    isLoading: state.get("pageManager").get("loading"),
    successMsg: state.get("pageManager").get("successMsg"),
    errorMsg: state.get("pageManager").get("errorMsg"),
    isAuthenticate: state
      .get("pageManager")
      .get("security")
      .get("isAuthenticate"),
    userInfo: state.get("pageManager").get("security").get("userInfo"),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Reset);
