export const validateFieldStyle = (applyStyle, fieldError) => {
  let style = "";
  if (applyStyle) {
    style = "is-valid";
    if (fieldError) {
      style = "is-invalid";
    }
  }
  return style;
};

export const convertTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
}

export const formatDate = (date) => {
  return [
    convertTo2Digits(date.getDate()),
    convertTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('/');
}

export const formatDateDB = (date) => {
  try {
    return [
      date.getFullYear(),
      convertTo2Digits(date.getMonth() + 1),
      convertTo2Digits(date.getDate()),
    ].join('-');
  } catch (error) {
    return null;
  }
 
}
